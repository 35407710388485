<template>
  <div class="info-list">
    <ul>
      <li>{{ item.deptName }}</li>
      <li>状态：{{ onlineStatus }}（{{ lastUpdateInterval }}前更新）</li>
      <li>最近一次点位上报：{{ item.deviceTime }}</li>
      <li>随身设备电量：{{ item.soc }}%</li>
      <li>当日步行距离：{{ miles }}</li>
    </ul>
  </div>
</template>

<script>
import { formatTime, formatMiles } from '@/utils/formatHelper'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    lastUpdateTime() {
      return this.item.deviceTime
    },
    lastUpdateInterval() {
      console.log('lastUpdateInterval')
      const deviceTime = this.item.deviceTime
      if (deviceTime) {
        const lastTime = new Date(deviceTime).getTime()
        const nowTime = new Date().getTime()
        const intervalTime = Math.floor((nowTime - lastTime) / 1000)
        const dayInterval = Math.floor(intervalTime / 60 / 60 / 24)
        let timeStr = ''
        if (dayInterval > 0) {
          timeStr = dayInterval + '天'
        } else {
          timeStr = formatTime(intervalTime)
        }
        return timeStr
      } else {
        return 0
      }
    },
    onlineStatus() {
      return this.item.online ? '在线' : '离线'
    },
    miles() {
      return formatMiles(this.item.distance)
    }
  }
}
</script>
<style lang="less" scoped>
.info-list {
  // margin-left: 110px;
  margin-top: -5px;
  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
.info-list ul li {
  // height: 24px;
  line-height: 24px;
  color: #000;
}
</style>
