import moment from 'moment'
// 格式化时间秒
export function formatTime(seconds) {
  return moment.utc(seconds * 1000).format('HH:mm:ss')
}

// 格式化单位米
export function formatMiles(m) {
  const distance = m ? Number(m) : 0
  return distance ? (distance > 1000 ? (distance / 1000).toFixed(3) + '公里' : distance + '米') : '-'
}
// 格式化速度
export function formatSpeed(s) {
  return (s * 3600) / 1000
}
