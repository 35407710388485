<template>
  <div class="workers_s">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <div class="status1"><img :src="item.src" width="100%" /></div>
        <span>{{ item.value }}</span>
        <div class="s_txt">{{ item.label }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {}
    }
  }
}
</script>
<style lang="less" scoped>
.workers_s ul li {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding-right: 30px;
  overflow: hidden;
  color: #000;
}
.workers_s ul li span {
  display: block;
  text-align: right;
  float: right;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
}
.workers_s ul li .status1 {
  width: 30px;
  float: left;
  margin-left: 40px;
}
.workers_s ul li .s_txt {
  margin-left: 110px;
}
ul {
  margin: 0;
  padding: 0;
}
</style>
