var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "group-title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _c("span", [_vm._v(_vm._s(_vm.secondTitle))]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "group-title-animate" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }