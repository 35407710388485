/**
 * WGS84转GCj02
 * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
 * @param lng
 * @param lat
 * @returns {*[]}
 */

const xPI = (3.14159265358979324 * 3000.0) / 180.0
const PI = 3.1415926535897932384626
const a = 6378245.0
const ee = 0.00669342162296594323
const coordinateTransform = {
  wgs84togcj02tobd09(lng, lat) {
    // WGS84转GCj02
    let dlat = this.transformlat(lng - 105.0, lat - 35.0)
    let dlng = this.transformlng(lng - 105.0, lat - 35.0)
    const radlat = (lat / 180.0) * PI
    let magic = Math.sin(radlat)
    magic = 1 - ee * magic * magic
    const sqrtmagic = Math.sqrt(magic)
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
    const mglat = lat + dlat
    const mglng = lng + dlng
    // 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
    const z = Math.sqrt(mglng * mglng + mglat * mglat) + 0.00002 * Math.sin(mglat * xPI)
    const theta = Math.atan2(mglat, mglng) + 0.000003 * Math.cos(mglng * xPI)
    const bdlng = z * Math.cos(theta) + 0.0065
    const bdlat = z * Math.sin(theta) + 0.006
    // return [bdlng, bdlat]
    return { lng: bdlng, lat: bdlat }
  },
  transformlat(lng, lat) {
    const PI = 3.1415926535897932384626
    let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
    ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
    ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
    ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
    return ret
  },
  transformlng(lng, lat) {
    const PI = 3.1415926535897932384626
    let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
    ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
    ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
    ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
    return ret
  },
  // 百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02) 的转换
  bd09togcj02(bdlng, bdlat) {
    bdlng = +bdlng
    bdlat = +bdlat
    var x = bdlng - 0.0065
    var y = bdlat - 0.006
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * xPI)
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xPI)
    var gglng = z * Math.cos(theta)
    var gglat = z * Math.sin(theta)
    return [gglng, gglat]
  },
  // GCJ-02 转换为 WGS-84
  gcj02towgs84(lng, lat) {
    lat = +lat
    lng = +lng
    if (this.out_of_china(lng, lat)) {
      return [lng, lat]
    } else {
      var dlat = this.transformlat(lng - 105.0, lat - 35.0)
      var dlng = this.transformlng(lng - 105.0, lat - 35.0)
      var radlat = (lat / 180.0) * PI
      var magic = Math.sin(radlat)
      magic = 1 - ee * magic * magic
      var sqrtmagic = Math.sqrt(magic)
      dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
      dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
      var mglat = lat + dlat
      var mglng = lng + dlng
      return [lng * 2 - mglng, lat * 2 - mglat]
    }
  },
  // 百度坐标转为84坐标
  bd09towgs84(lng, lat) {
    lat = +lat
    lng = +lng
    const gcj02 = this.bd09togcj02(lng, lat)
    return this.gcj02towgs84(gcj02[0], gcj02[1])
  },

  out_of_china(lng, lat) {
    lat = +lat
    lng = +lng
    // 纬度 3.86~53.55, 经度 73.66~135.05
    return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55)
  }
}

export default coordinateTransform
