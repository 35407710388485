<template>
  <div class="info-list">
    <ul>
      <li>车辆自编号：{{ item.bindKey }}</li>
      <li>车牌号：{{ item.bindName }}</li>
      <li>速度：{{ speed }}</li>
      <li>方向：{{ locationHeading }}° <img ref="headingRef" :src="arrowUrl" /></li>
      <li>最近一次点位上报：{{ item.deviceTime }}</li>
    </ul>
  </div>
</template>

<script>
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  mixins: [MonitorBase],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    speed() {
      const speed = this.item.speed ? this.item.speed : ''
      if (speed) {
        return (Number(speed) / 10).toFixed(2) + 'KM/H'
      } else {
        return '暂无速度'
      }
    },
    locationHeading() {
      const direction = this.item.direction
      this.$nextTick(() => {
        const el = this.$refs.headingRef
        if (el) {
          this.setArrow(el, direction)
        }
      })
      return direction ? direction.toFixed(2) : direction
    }
  }
}
</script>
<style lang="less" scoped>
.info-list {
  //   margin-left: 110px;
  margin-top: -5px;
  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
.info-list ul li {
  height: 24px;
  line-height: 24px;
  color: #000;
}
</style>
