var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-list" }, [
    _c("ul", [
      _c("li", [_vm._v(_vm._s(_vm.item.deptName))]),
      _c("li", [
        _vm._v(
          "状态：" +
            _vm._s(_vm.onlineStatus) +
            "（" +
            _vm._s(_vm.lastUpdateInterval) +
            "前更新）"
        ),
      ]),
      _c("li", [_vm._v("最近一次点位上报：" + _vm._s(_vm.item.deviceTime))]),
      _c("li", [_vm._v("随身设备电量：" + _vm._s(_vm.item.soc) + "%")]),
      _c("li", [_vm._v("当日步行距离：" + _vm._s(_vm.miles))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }