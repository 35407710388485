import { render, staticRenderFns } from "./cleaner-info.vue?vue&type=template&id=0cb8442c&scoped=true&"
import script from "./cleaner-info.vue?vue&type=script&lang=js&"
export * from "./cleaner-info.vue?vue&type=script&lang=js&"
import style0 from "./cleaner-info.vue?vue&type=style&index=0&id=0cb8442c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb8442c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cb8442c')) {
      api.createRecord('0cb8442c', component.options)
    } else {
      api.reload('0cb8442c', component.options)
    }
    module.hot.accept("./cleaner-info.vue?vue&type=template&id=0cb8442c&scoped=true&", function () {
      api.rerender('0cb8442c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/cleaner/light/cleaner-info.vue"
export default component.exports